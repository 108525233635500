import { graphql, StaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import React from 'react'

import './about-me.scss'

const query = graphql`
query AboutMePageContent {
	strapiAboutMeContent {
        Content
        Image {
        childImageSharp {
        gatsbyImageData(
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, PNG]
        )
    }
    }
  }
}`;

export default function AboutMe() {
    return (
        <div>
            <StaticQuery query={query} render={
                (data) => {
                    return (
                        <div className="about-me-container">
                            <div className="about-me-content">
                                <GatsbyImage image={getImage(data.strapiAboutMeContent.Image)} />
                                {/* {JSON.stringify(Object.keys(data.allStrapiAboutMeContent.edges[0].node.image))} */}
                                <div>
                                    {data.strapiAboutMeContent.Content.split('\n').map(e => {
                                        return (<div>
                                            <p className="h3">{e}</p>
                                            <br />
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                }
            } />
        </div>
    )
}
