import React from 'react'
import { Helmet } from 'react-helmet'
import Page from '../container/page'
import AboutMe from '../container/about-me/about-me'

export default function Hire() {
    return [
        <Helmet>
            <title>Joshua Carroll | Hire Me</title>
        </Helmet>,
        <Page>
            <AboutMe />
        </Page>
    ]
}
